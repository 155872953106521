import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const drawerWidth = 360

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            height: '100%',
            width: '100%',
        },
        drawer: {
            [theme.breakpoints.up('md')]: {
                width: drawerWidth,
                maxWidth: '100vw',
                flexShrink: 0,
            },
        },
        drawerPaper: {
            width: drawerWidth,
            maxWidth: '100vw',
        },
        main: {
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
        },
        mainContent: {
            'flexGrow': 1,
            'display': 'flex',
            'flexDirection': 'column',
            '& > div': {
                position: 'relative',
                height: '100%',
                width: '100%',
                margin: 0,
                padding: 0,
                border: 'none',
                pointerEvents: 'auto',
                top: 'auto',
                bottom: 'auto',
                left: 'auto',
                right: 'auto',
            },
        },
        appBar: {
            top: 'auto',
            bottom: 0,
        },
    }),
)

export default useStyles
