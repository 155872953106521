import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            position: 'relative',
        },
        button: {
            position: 'absolute',
            bottom: theme.spacing(),
            right: theme.spacing(),
        },
    }),
)

export default useStyles
