import * as React from 'react'
import './root.css'
import { AppBar, Drawer, Hidden } from '@material-ui/core'
import useStyles from './layout.css'
import TopBanner from './topBanner'
import clsx from 'clsx'

interface Props {
    drawer?: (handleClose: () => void) => React.ReactNode
    bottomBar?: React.ReactNode
    className?: string
}

const Layout: React.FC<Props> = ({ children, drawer, bottomBar, className }) => {
    const classes = useStyles()
    const [mobileOpen, setMobileOpen] = React.useState(false)

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen)
    }
    const handleClose = () => {
        setMobileOpen(false)
    }

    return (
        <div className={clsx(classes.root, className)}>
            {drawer && (
                <nav className={classes.drawer}>
                    <Hidden mdUp implementation="css">
                        <Drawer
                            variant="temporary"
                            open={mobileOpen}
                            onClose={handleDrawerToggle}
                            classes={{
                                paper: classes.drawerPaper,
                            }}
                            ModalProps={{
                                keepMounted: true, // Better open performance on mobile.
                            }}
                        >
                            {drawer(handleClose)}
                        </Drawer>
                    </Hidden>
                    <Hidden smDown implementation="css">
                        <Drawer
                            classes={{
                                paper: classes.drawerPaper,
                            }}
                            variant="permanent"
                            open
                        >
                            {drawer(handleClose)}
                        </Drawer>
                    </Hidden>
                </nav>
            )}
            <main className={classes.main}>
                <Hidden mdUp implementation="css">
                    <TopBanner handleDrawerToggle={handleDrawerToggle} />
                </Hidden>
                <div className={classes.mainContent}>{children}</div>
                {bottomBar && (
                    <AppBar position="relative" color="secondary" elevation={0}>
                        {bottomBar}
                    </AppBar>
                )}
            </main>
        </div>
    )
}

export default Layout
