import * as React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import { Button, Paper, useMediaQuery } from '@material-ui/core'
import useTheme from '@material-ui/core/styles/useTheme'
import useStyles from './topBanner.css'

interface Props {
    handleDrawerToggle: () => void
}

const TopBanner: React.FC<Props> = ({ handleDrawerToggle }) => {
    const theme = useTheme()
    const classes = useStyles()
    const {
        topBannerSm: {
            childImageSharp: { fluid: topBannerSm },
        },
        topBannerMd: {
            childImageSharp: { fluid: topBannerMd },
        },
    } = useStaticQuery(graphql`
        query {
            topBannerMd: file(relativePath: { eq: "banner-md.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 960) {
                        ...GatsbyImageSharpFluid_withWebp
                        ...GatsbyImageSharpFluidLimitPresentationSize
                    }
                }
            }
            topBannerSm: file(relativePath: { eq: "banner.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 600) {
                        ...GatsbyImageSharpFluid_withWebp
                        ...GatsbyImageSharpFluidLimitPresentationSize
                    }
                }
            }
        }
    `)
    const sm = useMediaQuery(theme.breakpoints.down('xs'))
    let image = topBannerMd
    if (sm) {
        image = topBannerSm
    }
    return (
        <Paper
            variant="elevation"
            square
            elevation={1}
            classes={{
                root: classes.root,
            }}
        >
            <Img fluid={image} alt="On Va Au resto" />
            <Button
                variant="contained"
                color="secondary"
                classes={{
                    root: classes.button,
                }}
                onClick={handleDrawerToggle}
            >
                Rechercher par nom
            </Button>
        </Paper>
    )
}

export default TopBanner
